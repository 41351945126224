<template>
  <div v-if="!authenticated">
    <AuthComp />
  </div>
  <div v-else>
      <router-view />
    </div>
</template>
<script>
import { defineAsyncComponent } from '@vue/runtime-core';
import { mapState } from 'vuex';
export default {

  computed: {
    ...mapState(['authenticated'])
  },

  components: {
    AuthComp: defineAsyncComponent(() =>
      import("./components/AuthView.vue")
    )

  }

}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
