import planningApi from '@/api/PlanningApi'
import createPersistedState from "vuex-persistedstate"
import Swal from "sweetalert2";

import { createStore } from 'vuex'

export default createStore({
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })],
    state: {
        vada: '',
        plannings: [],
        planningsTodo: [],
        isLoading: true,
        range: 0,
        hidden: 0,
        authenticated: false,
        refreshToken: ''
    },
    
    getters: {
        getDecaconvo: (state) => async () => {
            const path = `/api/v1/planningsByVadaForDecaconvo?vada=${state.vada}`
            const response = await planningApi.get(path)
            if (!response.data) return
            state.planningsTodo = [...response.data]
            return response.data
        },
        getDataDecaconvo: (state) => async () => {
            const arrayData = []

            const date = new Date();
            let currentDate = date;
            const dayInMiliseconds = 1000 * 60 * 60 * 24,
                daysWeek = 7,
                thursday = 4;
            currentDate = new Date(
                Date.UTC(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate()
                )
            );
            let dayWeek = currentDate.getUTCDay();
            if (dayWeek === 0) {
                dayWeek = 7;
            }
            currentDate.setUTCDate(currentDate.getUTCDate() - dayWeek + thursday);
            const startYear = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
            const diff = currentDate - startYear;
            const week = Math.ceil((diff / dayInMiliseconds + 1) / daysWeek)

            state.planningsTodo.forEach(p => {
                const planningWeek = parseInt(p.week.split('-W')[1])
                const planningDay = parseInt(p.day)
                const day = date.getDay() === 0 ? 7 : date.getDay()
                const hourPlanning = parseInt(p.hour_load.split(':')[0])
                const minutePlanning = parseInt(p.hour_load.split(':')[1])
                if (day <= planningDay || planningWeek !== week) {
                    const planningMinutes = hourPlanning * 60 + minutePlanning
                    const currentMinutes = date.getHours() * 60 + date.getMinutes()
                    if (planningMinutes - currentMinutes >= state.hidden || day != planningDay) {
                        arrayData.push(p)

                    }
                }
            })
            state.plannings = arrayData

            state.isLoading = false
        },
        getRange: (state) => async () => {
            const path = `/api/v1/timeRange?vada=${state.vada}`
            
            const response = await planningApi.get(path)
            state.range = response.data.message
        },
        getHidden: (state) => async () => {
            const path = `/api/v1/timeHidden?vada=${state.vada}`
            
            const response = await planningApi.get(path)
            state.hidden = response.data.message
        },
        getCenters: (state) => async () => {
            const path = "/api/v1/centers"
            
            const response= await planningApi.get(path)
            if (response.status !== 200) {
                Swal.fire({
                    icon: "error",
                    title: `Error ${response.status}`,
                    text: response.statusText,
                    confirmButtonColor: "#007DBC",
                    cancelButtonColor: "#6e7d88"
                })
            }

            if (response.data) {

                state.centers = [...response.data]
                state.isLoading = false
            } else {
                state.isLoading = false
            }
        },
    },
    mutations: {
        setVada(state, value) {
            state.vada = value
        },

        setRefreshToken(state, value) {
            state.refreshToken = value
        },
        isAuthenticated (state) {
            state.authenticated = true
        },
        
       isAuthenticatedToFalse (state) {
            state.authenticated = false
        }
    },
    actions: {
         openFedid (context, data){
            const url = `https:${data.environment === 'production' ? '' : 'preprod.'}idpdecathlon.oxylane.com/as/authorization.oauth2?response_type=code&client_id=${data.clientId}&state=myRandomState&scope=openid%20profile&redirect_uri=${window.location.origin}/`;
            window.location.href = url;
        },
         isAuthenticated ({ commit })  {
            commit('isAuthenticated')
        },
        
         isAuthenticatedToFalse({ commit })  {
            commit('isAuthenticatedToFalse')
        },

    },
    modules: {}
})