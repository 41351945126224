import axios from 'axios'

const url = process.env.VUE_APP_HOST_PLANNING
const planningApi = axios.create({
    headers: {
        'x-api-key':process.env.VUE_APP_CLIENT_API_KEY_PLANNING,
        'Strict-Transport-Security':'max-age=315360000;includeSubDomains',
        'Content-Security-Policy':'default-src https: "self"',
        'X-XSS-Protection':'1',
        'X-Frame-Options':'DENY',
        'X-Content-Type-Options':'nosniff',
        'Cache-Control':'no-store',
        'Referrer-Policy':'no-referrer',
        'Permissions-Policy': 'web-share=(), picture-in-picture=()' 
        },
    baseURL: url
})
planningApi.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
        Promise.reject("AUTH ERROR OCCURRED:" + error);
    }
  );


export default planningApi