import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
    path: '/',
    redirect: '/decaconvo'
},
{
    path: '/decaconvo',
    name: 'decaconvo',

    component: () =>
        import( /* webpackChunkName: "decaconvo" */ '../views/DecaconvoLayout.vue')
}
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


export default router