import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import crono from 'vue-crono'
import { createI18n } from 'vue-i18n'
import {messages} from './plugins/i18n.js'
import './index.css'
import 'typeface-roboto';
import 'typeface-roboto-condensed';
import '@vtmn/icons/dist/vitamix/font/vitamix.css';
import '@vtmn/css/dist/index.css';

export const i18n = createI18n({
    locale:  'en', 
    fallbackLocale: 'en', 
    globalInjection: true,
    messages, 
  })

createApp(App).use(store).use(router).use(i18n).use(crono).mount('#app')
